#Papers {
    background: rgb(45,26,59);
    background: linear-gradient(4deg, rgba(45,26,59,0.19931722689075626) 48%, rgba(58,42,66,0.5242471988795518) 93%);
}

#Papers .card {
    background-color: rgb(7, 7, 43);
    color: white;
    border-color: rgb(64, 21, 104);
    border-radius: 1em;
    opacity: 0.9;
    margin-top: 1em;
    margin-bottom: 1em;
}

#Papers .card:hover {
    border-radius: 1em !important;
    border-color: rgb(109, 79, 138) !important;
    cursor: default;
}

#Papers .embedDiv {
    text-align: center;
    height: 300px;
    padding: 1em;
}

#Papers embed {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;
}

#Papers .collapse-btn {
    margin-top: -15px;
    padding: 0;
    font-size: 2em;
    background-color: inherit;
    border: none;
}

#Papers .card .card-footer {
    padding-top: 0;
}
