#Work {
    background: rgb(45,26,59);
    background: linear-gradient(192deg, rgba(45,26,59,0.19931722689075626) 64%, rgba(71,35,89,0.5242471988795518) 93%);
}

#workCardDiv .col {
    margin-bottom: 1em;
    margin-top: 1em;
}

#workCardDiv .card-img-top {
    padding-left: 6em;
    padding-right: 6em;
    padding-top: 1em;
}

#workCardDiv .redirect-icon {
    position: absolute;
    right: 0.5rem;
}

#workCardDiv .card {
    background-color: rgb(7, 7, 43);
    color: white;
    border-color: rgb(64, 21, 104);
    border-radius: 1em;
    opacity: 0.9;
}

#workCardDiv .card:hover {
    border-radius: 1em !important;
    border-color: rgb(109, 79, 138) !important;
    cursor: default;
}

#workCardDiv .card .summary {
    text-align: justify;
}
