@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: Raleway, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("img/background.jpg") no-repeat center center fixed;
  background-size: cover;
  background-color: black;
}

@media only screen and (max-width: 961px) {
  body {
    background: url("img/background.jpg") left;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#GoBackUp {
  position: fixed;
  bottom: 2em;
  right: 2em;
  color: white;
}

h1,
h2,
h3,
h4 {
  color: white !important;
}

.sectionHeader {
  font-size: 60px;
}
